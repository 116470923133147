<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <!-- <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>应用Id</span>
            <a-input
              placeholder="应用Id"
              class="filter_input"
              v-model.trim="queryParam.appId"
            />
          </div>
        </a-col> -->

        <!-- <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>登录名</span>
            <a-input
              placeholder="登录名"
              class="filter_input"
              v-model.trim="queryParam.username"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>手机号</span>
            <a-input
              placeholder="手机号"
              class="filter_input"
              v-model.trim="queryParam.mobile"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>支付id</span>
            <a-input
              placeholder="支付id"
              class="filter_input"
              v-model.trim="queryParam.payAccount"
            />
          </div>
        </a-col> -->
        <a-col
          :lg="3"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
            >
              查询
            </a-button>
            <a-button @click="resetQuery">重置</a-button>
            <a-button
              type="primary"
              style="margin-left: 12px;"
              @click="openInsertModal"
            >
              新增
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="user_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <template slot="merchantPrivateKey" slot-scope="text, record">
          <template v-if="text">
            {{
              text.substring(0, 4) +
                "……" +
                text.substring(text.split("").length - 4)
            }}
            <a @click="copyInfo(text)">复制</a>
          </template>
          <span v-else>-</span>
        </template>

        <template slot="alipayCertPublicKeyPath" slot-scope="text, record">
          <template v-if="text">
            {{
              text.substring(0, 4) +
                "……" +
                text.substring(text.split("").length - 4)
            }}
            <a @click="copyInfo(text)">复制</a>
          </template>
          <span v-else>-</span>
        </template>

        <template slot="alipayPublicKey" slot-scope="text, record">
          <template v-if="text">
            {{
              text.substring(0, 4) +
                "……" +
                text.substring(text.split("").length - 4)
            }}
            <a @click="copyInfo(text)">复制</a>
          </template>
          <span v-else>-</span>
        </template>

        <template slot="alipayRootCertPath" slot-scope="text, record">
          <template v-if="text">
            {{
              text.substring(0, 4) +
                "……" +
                text.substring(text.split("").length - 4)
            }}
            <a @click="copyInfo(text)">复制</a>
          </template>
          <span v-else>-</span>
        </template>

        <template slot="appCertPublicKeyPath" slot-scope="text, record">
          <template v-if="text">
            {{
              text.substring(0, 4) +
                "……" +
                text.substring(text.split("").length - 4)
            }}
            <a @click="copyInfo(text)">复制</a>
          </template>
          <span v-else>-</span>
        </template>
        <span slot="enable" slot-scope="text">{{ text ? "是" : "否" }}</span>
        <template slot="operation" slot-scope="text, record">
          <a @click="update(record)">修改</a>
          <a-popconfirm
            title="确认删除该支付信息吗?"
            ok-text="确认"
            cancel-text="否"
            @confirm="deleteInfo(record.id)"
          >
            <a style="color: red; margin-left: 6px">删除</a>
          </a-popconfirm>
        </template>
      </s-table>
    </div>

    <a-modal
      title="修改"
      :width="560"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="input_group">
        <span>应用ID：</span>
        <a-input class="top5" placeholder="应用ID" v-model="edit.appId" />
      </div>
      <div class="input_group">
        <span>加密密钥：</span>
        <a-input
          class="top5"
          placeholder="加密密钥"
          v-model="edit.encryptKey"
        />
      </div>
      <div class="input_group">
        <span>商户私钥：</span>
        <a-input
          class="top5"
          placeholder="商户私钥"
          v-model="edit.merchantPrivateKey"
        />
      </div>
      <!-- <div class="input_group">
        <span>支付宝公钥：</span>
        <a-input class="top5" placeholder="支付宝公钥" v-model="edit.alipayPublicKey" />
      </div> -->
      <div
        class="input_group"
        :class="{ nomargin: !!edit.alipayCertPublicKeyPath }"
      >
        <span>支付宝证书公钥：</span>
        <a-upload
          :showUploadList="false"
          :customRequest="f => handleChange(f, 'alipayCertPublicKeyPath')"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </div>
      <p
        v-if="edit.alipayCertPublicKeyPath"
        style="padding-left: 120px; margin-bottom: 12px;"
      >
        <a>{{ edit.alipayCertPublicKeyPath }}</a>
        <a style="color: red; margin-left: 6px" @click="clearValue('alipayCertPublicKeyPath')">删除</a>
      </p>

      <div
        class="input_group"
        :class="{ nomargin: !!edit.alipayRootCertPath }"
      >
        <span>支付宝根证书：</span>
        <a-upload
          :showUploadList="false"
          :customRequest="f => handleChange(f, 'alipayRootCertPath')"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </div>
      <p
        v-if="edit.alipayRootCertPath"
        style="padding-left: 120px; margin-bottom: 12px;"
      >
        <a>{{ edit.alipayRootCertPath }}</a>
        <a style="color: red; margin-left: 6px" @click="clearValue('alipayRootCertPath')">删除</a>
      </p>

      <div
        class="input_group"
        :class="{ nomargin: !!edit.appCertPublicKeyPath }"
      >
        <span>应用证书公钥：</span>
        <a-upload
          :showUploadList="false"
          :customRequest="f => handleChange(f, 'appCertPublicKeyPath')"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </div>
      <p
        v-if="edit.appCertPublicKeyPath"
        style="padding-left: 120px; margin-bottom: 12px;"
      >
        <a>{{ edit.appCertPublicKeyPath }}</a>
        <a style="color: red; margin-left: 6px" @click="clearValue('appCertPublicKeyPath')">删除</a>
      </p>
      
      <div class="input_group">
        <span>通知地址：</span>
        <a-input class="top5" placeholder="通知地址" v-model="edit.notifyUrl" />
      </div>
      <div class="input_group">
        <span>是否启用：</span>
        <a-switch v-model="edit.enable" />
      </div>
      <div class="input_group">
        <span>备注：</span>
        <a-input class="top5" placeholder="备注" v-model="edit.remark" />
      </div>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  queryPayInfo,
  insertPayInfo,
  updatePayInfo,
  removePayInfo,
  filePreUpload,
  uploadOSS
} from "@api";
import { formatDate } from "@/utils/util";

export default {
  data() {
    return {
      edit: { show: false },
      queryParam: {},
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" }
        },
        {
          title: "应用Id",
          dataIndex: "appId"
        },
        {
          title: "加密密钥",
          dataIndex: "encryptKey"
        },
        {
          title: "商户私钥",
          dataIndex: "merchantPrivateKey",
          scopedSlots: { customRender: "merchantPrivateKey" }
        },
        {
          title: "支付宝公钥",
          dataIndex: "alipayPublicKey",
          scopedSlots: { customRender: "alipayPublicKey" }
        },
        {
          title: "支付宝证书公钥",
          dataIndex: "alipayCertPublicKeyPath",
          scopedSlots: { customRender: "alipayCertPublicKeyPath" }
        },
        {
          title: "支付宝根证书",
          dataIndex: "alipayRootCertPath",
          scopedSlots: { customRender: "alipayRootCertPath" }
        },
        {
          title: "应用证书公钥",
          dataIndex: "appCertPublicKeyPath",
          scopedSlots: { customRender: "appCertPublicKeyPath" }
        },
        {
          title: "通知地址",
          dataIndex: "notifyUrl"
        },
        {
          title: "备注",
          dataIndex: "remark"
        },
        {
          title: "是否启用",
          dataIndex: "enable",
          scopedSlots: { customRender: "enable" }
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "操作",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: parameter => {
        return queryPayInfo(Object.assign(parameter, this.queryParam)).then(
          result => {
            result.data.list.forEach(item => (item.showid = false));
            return result.data;
          }
        );
      }
    };
  },
  components: {
    PageView,
    STable
  },
  methods: {
    chanegEditBlocked() {
      this.edit.enable = !this.edit.enable;
    },
    // 重置
    resetQuery() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },
    openInsertModal() {
      this.edit = { show: true };
    },

    handleMouseover(record) {
      record.showid = true;
    },
    handleMouseout(record) {
      record.showid = false;
    },
    update(record) {
      record.show = true;
      this.edit = JSON.parse(JSON.stringify(record));
    },
    async deleteInfo(id) {
      removePayInfo(id).then(res => {
        if (res) {
          this.$message.success("成功删除");
          this.$refs.table.refresh(false);
        } else {
          this.$message.error("出错了");
        }
      });
    },
    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      const method = edit.id ? updatePayInfo : insertPayInfo
      //修改前三个
      method({
        id: edit.id,
        appId: edit.appId,
        encryptKey: edit.encryptKey,
        merchantPrivateKey: edit.merchantPrivateKey,
        alipayPublicKey: edit.alipayPublicKey,
        alipayCertPublicKeyPath: edit.alipayCertPublicKeyPath,
        alipayRootCertPath: edit.alipayRootCertPath,
        appCertPublicKeyPath: edit.appCertPublicKeyPath,
        notifyUrl: edit.notifyUrl,
        remark: edit.remark,
        enable: edit.enable,
      }).then((res) => {
        if (res.success) {
            //没有role直接提示
            this.$message.success('成功');
            this.$refs.table.refresh(false);
            this.edit = { show: false };
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clearValue(key) {
      this.$set(this.edit, key, "")
    },
    handleChange(fileData, atrKey) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${Date.now()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              let fileUrl =
                "http://czkj-cache.oss-cn-shenzhen.aliyuncs.com/" + key;
              console.log("[fileUrl] ---> ", fileUrl);
              this.$set(this.edit, atrKey, fileUrl);
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  // align-items: center;
  span {
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.nomargin {
  margin: 0 !important;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}
</style>
